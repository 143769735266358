import React, { useContext, useState } from 'react';
import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
    Checkbox,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { addBatch } from "../../../apiclients/AcademyClient";
import { DAYS_OF_WEEK, SKILLS, DURATION } from '../../../utils/constants';

const AddBatch = (props) => {
    const { showModal, setReload, setMessage, isLoading, selectedSport } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const sportId = selectedSport.key;

    const [batchName, setBatchName] = useState();
    const [skill, setSkill] = useState();
    const [duration, setDuration] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [fees, setFees] = useState();
    const [registrationFees, setRegistrationFees] = useState();
    const [days, setDays] = useState([]);
     
    const [remarks, setRemarks] = useState();


    const isSubmitAllowed = batchName && skill && startTime && endTime && fees && days.length > 0 && duration && remarks && registrationFees;


    const updateAllDays = (checked) => {
        if (checked) {
            setDays(DAYS_OF_WEEK);
        } else {
            setDays([]);
        }
    };

    const updateDays = (selectedDay) => {
        if (days.some(o => o.key === selectedDay.key)) {
            setDays(days.filter(o => o.key !== selectedDay.key));
        } else {
            setDays([...days, selectedDay]);
        }

    };

    const changeSkill = (event, { value }) => {
        setSkill(value);
    };
    const changeDuration = (event, { value }) => {
        setDuration(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Batch Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={batchName}
                            placeholder='Beginner - MWF - 4-5 PM'
                            onChange={(evt) => setBatchName(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Skills</label>
                        <Dropdown
                            onChange={changeSkill}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={skill}
                            options={SKILLS}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Monthly Fees</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={fees}
                            placeholder='5000'
                            onChange={(evt) => setFees(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Registration Fees</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={registrationFees}
                            placeholder='1000'
                            onChange={(evt) => setRegistrationFees(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Start Time</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={startTime}
                            placeholder='16:00'
                            onChange={(evt) => setStartTime(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>End Time</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={endTime}
                            placeholder='17:00'
                            onChange={(evt) => setEndTime(evt.target.value)}

                        />
                    </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>

                    <Form.Field  >

                        <label>
                            Days
                            <Checkbox
                                checked={
                                    DAYS_OF_WEEK.length === days.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllDays(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Days </span>
                        </label>
                        {DAYS_OF_WEEK.map((x) => {
                            const isActive = days.some(
                                (mode) => mode.key === x.key
                            );
                            return (
                                <Button
                                    key={x.key}
                                    onClick={() => { updateDays(x) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.text}
                                </Button>
                            );
                        })}

                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='E.G. Shuttles included'
                            onChange={(evt) => setRemarks(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Duration</label>
                        <Dropdown
                            onChange={changeDuration}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={duration}
                            options={DURATION}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>

                </Form.Group>

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={addBatchData}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addBatchData = () => {
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        showModal(false);
        isLoading(true);
        const postBody = {
            facilityId, arenaId, sportId, batchName, skill, days, startTime, endTime, fees, remarks, duration, registrationFees, createdBy
        };
        console.log('post body is ', postBody);

        addBatch(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Batch Added",
                    message: "Batch has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });
    }
    return (
        <>
            <ModalPortal
                title='Add Batch'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddBatch;